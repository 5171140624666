<template>
  <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M52.8284 6.82849C54.3905 5.26639 54.3905 2.73373 52.8284 1.17163C51.2663 -0.390463 48.7337 -0.390463 47.1716 1.17163L27 21.3432L6.82843 1.17163C5.26633 -0.390463 2.73367 -0.390463 1.17157 1.17163C-0.390524 2.73373 -0.390524 5.26639 1.17157 6.82849L21.3431 27.0001L1.17157 47.1716C-0.390528 48.7337 -0.390528 51.2664 1.17157 52.8285C2.73367 54.3906 5.26633 54.3906 6.82842 52.8285L27 32.6569L47.1716 52.8285C48.7337 54.3906 51.2663 54.3906 52.8284 52.8285C54.3905 51.2664 54.3905 48.7337 52.8284 47.1716L32.6569 27.0001L52.8284 6.82849Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
</style>