<template>
  <div class="light-box-container" v-show="zoom" :style="{opacity: shouldZoom}">
    <div class="top-space"></div>
    <IconButton bg="transparent" @onSomething="close">
      <Close/>
    </IconButton>
    <div class="image-container">
      <img :src="image"/>
    </div>
  </div>
</template>

<script>
import IconButton from "@/components/controls/button/IconOnlyButton.vue";
import Close from "@/components/svg/Close.vue";
import {store} from "@/store/store.js";

export default {
  store: store,
  props: {
    image: {
      type: String,
      default: "",
    },
    zoom: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IconButton,
    Close,
  },
  data() {
    return {
      closeIcon: close,
    };
  },
  computed: {
    shouldZoom() {
      return this.zoom ? 1 : 0;
    },
  },
  methods: {
    close() {
      this.$store.commit("setZoomClose");
    },
  },
};
</script>

<style scoped>
.top-space {
  height: 4rem;
}

.light-box-container {
  background-color: rgba(21, 22, 61, 0.568);
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 20;
  display: block;
}

.icon-only-button {
  margin: 2rem 2rem 0 0;
  float: right;
}

.image-container {
  z-index: 60;
  position: relative;
  top: 4rem;
}

img {
  object-fit: contain;
  width: auto;
  height: 50vw;
}
</style>